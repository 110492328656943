import Axios from 'axios';
import { createContext, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import authStorage from './auth';

const AxiosContext = createContext(undefined);

export function useAxios() {
    return useContext(AxiosContext);
}

const AxiosProvider = ({ children }) => {
    const navigate = useNavigate();
    const axios = useMemo(() => {
        const axios = Axios.create({
            baseURL: `${window.config.apiUrl}/PoliceAdmin/index.php/api/`,
            headers: {
                'Content-Type': 'application/json',
            },
        });

        axios.interceptors.request.use((config) => {
            const token = authStorage.getToken();
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }

            return config;
        });

        axios.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response.status === 401) {
                    navigate('/auth/login', { replace: true });
                }
                console.log(error);
                throw error;
            },
        );

        return axios;
    }, [navigate]);

    return (
        <AxiosContext.Provider value={axios}>{children}</AxiosContext.Provider>
    );
};

export default AxiosProvider;
